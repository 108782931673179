<template>
   <div class="personage">
     <i-header></i-header>
     <div class="percont">
       <div class="perLeft">
         <div class="account">
              <div class="photo"><img :src="avatar?avatar:require('@/assets/images/wdl.png')"></div>
              <p class="name">{{username}}</p>
              <p class="institution" v-show="branchName">所属机构：{{branchName}}</p>
            </div>
         <ul class="perMenu">
           <li :class="menuActive == index? 'menuActive':'' " v-for="(menu,index) in menuData" :key="index" @click="onMenu(menu)">{{menu.text}}</li>
         </ul>
         <p class="adduser" v-if="createUser" @click="toOpenPlatformRegisterUrl"><img src="../../assets/images/punctuation/zhanghao.png">新建账号</p>
       </div>
       <div class="perright">
        <!--个人信息-->
         <message v-if="vita == 0"></message>
        <!--我的收藏-->
         <collect v-else-if="vita == 1"></collect>
        <!--浏览记录-->
         <browse v-else-if="vita == 2"></browse>
        <!--我的笔记-->
         <mynotes v-else-if="vita == 3"></mynotes>
        <!--修改密码 -->
         <password v-else-if="vita == 4"></password>
       </div>
     </div>
   </div>
</template>

<script>
import iHeader from "@/components/Header.vue";
import message from "./message.vue";
import collect from "./collect.vue";
import browse from "./browse.vue";
import password from "./password.vue";
import mynotes from "./mynotes.vue";
import { checkWeiXinRegister,getOpenPlatformRegisterUrl } from "@/api/login"
export default {
  name: "personage",
  components:{
    iHeader,
    message,
    collect,
    password,
    browse,
    mynotes,
  },
  data(){
    return{
      menuData:[
        {text:'个人信息',path:'/personage'},
        {text:'我的收藏',path:'/myCollect'},
        {text:'浏览记录',path:'/myHistory'},
        {text:'我的笔记',path:'/mynotes'},
        {text:'修改密码',path:'/repassword'},
      ],
      menuActive:0,
      vita:0,
      createUser:false,
    }
  },
   watch: {
    "$route": {
        handler: function (to,from) {
          if(to.path && to.path=='/personage'){
            this.vita=0;
            this.menuActive = 0;
          }else if(to.path && to.path=='/myCollect'){
            this.vita=1;
            this.menuActive = 1;
          }else if(to.path && to.path=='/myHistory'){
            this.vita=2;
            this.menuActive = 2;
          }else if(to.path && to.path=='/mynotes'){
            this.vita=3;
            this.menuActive = 3;
          }else if(to.path && to.path=='/repassword'){
            this.vita=4;
            this.menuActive = 4;
          }
        },
        immediate: true,
        deep: true,
    },
  },
  computed:{
    avatar () {
        if(this.$store.state.avatar){
          return this.$store.state.avatar;
        }
    },
    username(){
        return this.$store.state.username;
    },
    branchName(){
        return this.$store.state.branchName;
    }
  },
  mounted(){
    this.checkWeiXinRegister();
  },
  methods:{
    onMenu(item){
      this.$router.push({path:item.path});
    },
    toWeixinRegister(){
      location.href='/api/public/weixinRegister';
    },
    toOpenPlatformRegisterUrl(){
      getOpenPlatformRegisterUrl().then((res)=>{
        if(res.code==200){
          location.href=res.url;
        }
      })
    },
    checkWeiXinRegister(){
      checkWeiXinRegister().then((res)=>{
        if(res && res.code==200 && res.permit){
          this.createUser=true;
        }
      })
    }
    
  },
}
</script>

<style scoped>
.personage{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/personage/bg.png");
  background-size: 100% 100%;
}
.personage >>> .box_logo{
  margin-left: 0px;
}
.percont{
  height: 100%;
  display: flex;
}
.perLeft{
  flex-shrink: 0;
  width: 262px;
  background: rgba(255,255,255,.14);
  padding-top: 140px;
  position: relative;
}
.account{
  width: 100%;
  height: 170px;
  background: rgba(5,5,5,.12);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}
.photo{
  width: 63px;
  height: 63px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}
.photo img{
  width: 100%;
  height: 100%;
}
.name{
  font-size: 14px;
  color: #FFFFFF;
  padding-top: 15px;
}
.institution{
  font-size: 14px;
  color: #ffffff;
  padding-top: 10px;
}
.perMenu{
  padding-top: 40px;
}
.perMenu li{
  color: #ffffff;
  font-size: 16px;
  height: 30px;
  cursor: pointer;
  width: 70px;
  margin: 0px auto 30px auto;
}
.menuActive{
  font-weight: bold;
  color: #D6DA00 !important;
  border-bottom: 2px solid #D6DA00;
}
.perright{
  flex: 1;
}
/*新建账号*/
.adduser{
  width: 176px;
  height: 48px;
  background: #D6DA00;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
  left: 43px;
}
.adduser img{
  width: 16px;
  height: 15px;
  margin-right: 5px;
}
</style>

<template>
   <div class="change">
     <p class="me_head">修改密码</p>
     <form>
       <div class="coded">
         <div class="colist">
           <label>旧密码：</label>
           <input type="password" v-model="oldPassword" placeholder="请输入旧密码">
         </div>
         <div class="colist">
           <label>新密码：</label>
           <input type="password" v-model="newPassword" placeholder="请输入新密码">
         </div>
         <div class="colist">
           <label>确认密码：</label>
           <input type="password" v-model="rePassword" placeholder="请再次输入新密码">
         </div>
         <div class="submit" @click="updatePassword">提交</div>
       </div>
     </form>
   </div>
</template>

<script>
import {updatePassword} from '@/api/userCenter';
export default {
  name: "password",
  data(){
    return{
      oldPassword:'',
      newPassword:'',
      rePassword:''
    }
  },
  methods:{
    updatePassword(){
      if(!this.oldPassword){
        this.$message.error("旧密码不能为空！");
        return;
      }
      if(!this.newPassword){
        this.$message.error("新密码不能为空！");
        return;
      }
      if(!this.rePassword){
        this.$message.error("确认密码不能为空！");
        return;
      }
      if(this.rePassword != this.newPassword){
        this.$message.error("新密码与确认密码不相同！");
        return;
      }
      if(this.oldPassword == this.newPassword){
        this.$message.error("新密码与旧密码不能相同！");
        return;
      }
      updatePassword({oldPassword:this.oldPassword,newPassword:this.newPassword})
      .then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg);
        }
      })
    }
  }
}
</script>

<style scoped>
.change{
  padding: 45px 50px;
  text-align: left;
  position: relative;
}
.me_head{
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 800;
}
.coded{
  padding-top: 120px;
  position: absolute;
  left: 50%;
  margin-left: -179px;
}
.colist{
  display: flex;
  align-items: center;
  width: 358px;
  height: 48px;
  background: rgba(255, 255, 255, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.49);
  border-radius: 2px;
  padding: 0px 13px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.colist label{
  font-size: 14px;
  color: #FFFFFF;
  width: 100px;
}
.colist input{
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  color: #ffffff;
}
.colist input::placeholder{
  color: #ffffff;
  opacity: 0.27;
}
.submit{
  width: 358px;
  height: 48px;
  background: #D6DA00;
  border-radius: 2px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  margin-top: 35px;
}
</style>

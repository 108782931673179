<template>
  <div class="collect">
    <div class="co_head">
      <h3>浏览记录</h3>
    </div>
    <ul class="have">
      <li v-for="(lis,lisindex) in haveData" :key="lisindex" @click="strpitem(lis)">
        <div class="pitch" v-show="select" @click="onSelect(lis)"><span :class="checkedKeys.includes(lis.id)?'ctrl':''"><input type="checkbox" ref="optional"></span></div>
        <div class="haveImg">
          <img :src="lis.titleImage" v-if="lis.titleImage">
          <div class="default" v-else></div>
        </div>
        <div class="descr">
          <p class="ellipsis">{{lis.name}}</p>
          <span>浏览时间：{{lis.createDate}}</span>
        </div>
      </li>
    </ul>
    <el-pagination
        background
        layout="total,prev, pager, next"
        :page-size="page.pageSize"
        :total="page.totalCount"
        @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { getUserHistoryList,collectItem} from "@/api/userCenter";
export default {
  name: "browse",
  data(){
    return{
      haveData:[],
      select:false,
      checkall:false,
      checkedKeys:[],
      page: {
        totalCount:0
      },
      pageNo: 1,
    }
  },
  methods:{
    /*接口*/
    getCollect(){
      getUserHistoryList({pageNo: this.pageNo,pageSize: 20}).then((res) => {
        this.haveData = res.data.list.map((item)=>{
          return {
            id:item.id,
            uuid:item.item.uuid,
            createDate:item.createDate,
            name:item.item.name,
            titleImage:item.item.titleImage?(item.item.titleImage.indexOf("?")>=0?(item.item.titleImage+'&h=300'):(item.item.titleImage+'?h=300')):''}
        });
        this.page = res.data;
      })
    },
    /*点击跳转*/
    strpitem(lis) {
      this.$router.push({
        name: "item",
        query: { sortId: lis.uuid },
      });
    },
    /*分页*/
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getCollect();
    },
  },
  created() {
    this.getCollect()
  }
}
</script>

<style scoped>
.collect{
  padding: 45px 0px 45px 50px;
  text-align: left;
}
.co_head{
  display: flex;
  align-items: center;
}
.co_head h3{
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 800;
  padding-right: 30px;
}
.co_head p{
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 35px;
  cursor: pointer;
}
.co_head p img,.co_head p span{
  margin-right: 5px;
}
.co_head p span{
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid rgba(255, 255, 255, 0.42);
  border-radius: 2px;
  display: inline-block;
  vertical-align: text-top;
}
.co_head p .checkall{
  background-image: url("../../assets/images/personage/xuanzhong.png");
  background-size: 8px 6px;
  background-repeat: no-repeat;
  background-position: center center;
}
.co_head p input{
  display: none;
  width: 14px;
  height: 14px;
}
.co_head .delete{
  color: #D6DA00;
}
.have{
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  height: 750px;
  overflow-y: auto;
}
.have::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 7px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.have::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #B3B3B3;
}
.have::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #3A362D;
}
.have li{
  width: 365px;
  height: 295px;
  position: relative;
  margin-right: 35px;
  margin-bottom: 20px;
}
.have li:nth-child(4n){
  margin-right: 0;
}
.haveImg{
  width: 100%;
  height: 228px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255,255,255,.1);
  border-bottom: none;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.default{
  position: absolute;
  background-image: url("../../assets/images/fenlei.png");
  background-size: 355px 220px;
  background-position: center center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.haveImg img{
  max-width: 100%;
  max-height: 100%;
}
.descr{
  width: 100%;
  height: 68px;
  background: rgba(0, 0, 0, 0.34);
  padding: 10px 15px;
  box-sizing: border-box;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.descr p{
  font-size: 16px;
  color: #FFFFFF;
  padding-bottom: 5px;
}
.descr span{
  font-size: 12px;
  color: #FFFFFF;
}
.pitch{
  position: absolute;
  left: 11px;
  top: 11px;
  z-index: 1;
}
.pitch span{
  display: inline-block;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.14);
  border: 1px solid #CCCCCC;
}
.pitch .ctrl{
  background-image: url("../../assets/images/personage/xuanzhong1.png");
  background-size: 14px 11px;
  background-repeat: no-repeat;
  background-position: center center;
}
.pitch span input{
  display: none;
  width: 24px;
  height: 24px;
}
.el-pagination{
  text-align: center;
  padding-top: 30px;
  padding-right: 40px;
}
.el-pagination >>> .el-pagination__total{
  color: #ffffff;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled).active{
  background-color: #D6DA00;
  color: #ffffff;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled):hover{
  color: #D6DA00;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled).active:hover{
  color: #ffffff;
}
</style>

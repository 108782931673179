<template>
   <div class="message">
      <p class="me_head">个人信息</p>
      <form>
        <div class="me_photo" >
           <div class="me_img"><img :src="avatar?avatar:require('@/assets/images/wdl.png')"></div>
           <span @click="editCropper()">修改头像</span>
        </div>
        <div class="me_list">
          <div class="me_strp">
            <label>昵称：</label>
            <input type="text" v-model="username"></input>
          </div>
          <div class="me_strp">
            <label>国籍：</label>
            <input type="text" v-model="country"></input>
          </div>
        </div>
        <div class="me_list">
          <div class="me_strp">
            <label>手机号：</label>
            <input type="text" v-model="phone"></input>
          </div>
          <div class="me_strp">
             <label>省份：</label>
            <input type="text" v-model="province"></input>
          </div>
        </div>
        <div class="me_list">
          <div class="me_strp">
            <label>性别：</label>
            <div class="gender">
               <div class="me_flex">
                 <p v-for="(item,index) in genderData" :key="index"><span @click="onGender(index)" :class="gender == index? 'genderActive':'' "><img :src="item.src"></span>{{item.text}}</p>
               </div>
            </div>
          </div>
          <div class="me_strp">
            <label>城市：</label>
            <input type="text" v-model="city"></input>
          </div>
        </div>
        <div class="me_list">
          <div class="me_strp">
            <label>生日：</label>
            <el-date-picker
              v-model="birthday"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <!-- <input type="text" :value="birthday"></input> -->
          </div>
          <div class="me_strp">
            <label>邮箱：</label>
            <input type="text" v-model="email" readonly></input>
          </div>
        </div>
        <div class="me_list">

          <div class="me_strp">
            <label>QQ：</label>
            <input type="text" v-model="qq"></input>
          </div>
        </div>
      </form>
      <div class="submit">
        <p @click="updateUserInfo">提交</p>
      </div>
      <!-- 裁剪图片弹出层 -->
      <el-dialog :title="title" :visible.sync="open" width="800px" append-to-body @opened="modalOpened">
      <el-row>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <vue-cropper
            ref="cropper"
            :img="options.img"
            :info="true"
            :autoCrop="options.autoCrop"
            :autoCropWidth="options.autoCropWidth"
            :autoCropHeight="options.autoCropHeight"
            :fixedBox="options.fixedBox"
            @realTime="realTime"
            v-if="visible"
          />
        </el-col>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <div :class="['avatar-circle','avatar-upload-preview']">
            <img :src="previews.url" :style="previews.img" />
          </div>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :lg="2" :md="2">
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
            <el-button size="small">
              选择
              <i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </el-upload>
        </el-col>
        <el-col :lg="{span: 1, offset: 2}" :md="2">
          <el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
        </el-col>
        <el-col :lg="{span: 2, offset: 6}" :md="2">
          <el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
        </el-col>
      </el-row>
    </el-dialog>
   </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import {uploadAvatar,updateUserInfo} from '@/api/userCenter';
export default {
  name: "message",
  components:{
    VueCropper
  },
  data(){
    return{
      // 是否显示弹出层
      open: false,
      // 是否显示cropper
      visible: false,
      // 弹出层标题
      title: "修改头像",
      options: {
        img: this.$store.state.avatar, //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true // 固定截图框大小 不允许改变
      },
      previews: {},
      genderData:[
        {src:require('../../assets/images/personage/nan.png'),text:'男'},
        {src:require('../../assets/images/personage/nv.png'),text:'女'},
        {src:require('../../assets/images/personage/baomi.png'),text:'保密'},
      ],
    }
  },
  computed:{
    avatar : {
      get: function(){
        if(this.$store.state.avatar){
          return this.$store.state.avatar;
        }
      },
      set: function (newValue) {
        this.$store.state.avatar=newValue;
      }
    },
    username:{
      get: function(){
        return this.$store.state.username;
      },
      set: function (newValue) {
        this.$store.state.username=newValue;
      }
    },
    phone:{
      get: function(){
        return this.$store.state.phone;
      },
      set: function (newValue) {
        this.$store.state.phone=newValue;
      }
    },
    gender:{
      get: function(){
        return this.$store.state.gender;
      },
      set: function (newValue) {
        this.$store.state.gender=newValue;
      }
    },
    birthday:{
      get: function(){
        return this.$store.state.birthday;
      },
      set: function (newValue) {
        this.$store.state.birthday=newValue;
      }
    },
    qq:{
      get: function(){
        return this.$store.state.qq;
      },
      set: function (newValue) {
        this.$store.state.qq=newValue;
      }
    },
    email:{
      get: function(){
        return this.$store.state.email;
      },
      set: function (newValue) {
        this.$store.state.email=newValue;
      }
    },
    country:{
      get: function(){
        return this.$store.state.country;
      },
      set: function (newValue) {
        this.$store.state.country=newValue;
      }
    },
    province:{
      get: function(){
        return this.$store.state.province;
      },
      set: function (newValue) {
        this.$store.state.province=newValue;
      }
    },
    city:{
      get: function(){
        return this.$store.state.city;
      },
      set: function (newValue) {
        this.$store.state.city=newValue;
      }
    },
    
  },
  methods:{
    updateUserInfo(){
      let userInfo={
        avatar:this.avatar,
        realName:this.username,
        gender:this.gender,
        birthday:this.birthday,
        qq:this.qq,
        phone:this.phone,
        country:this.country,
        province:this.province,
        city:this.city,
      }
      updateUserInfo(userInfo).then((res)=>{
        if(res.code==200){
          this.$message.success(res.msg);
          this.$store.dispatch('GetInfo');
        }
      })
    },
    onGender(index){
       this.gender = index
    },
    // 编辑头像
    editCropper() {
      this.open = true;
    },
    // 打开弹出层结束时的回调
    modalOpened() {
      this.visible = true;
    },
    // 覆盖默认的上传行为
    requestUpload() {
    },
    // 实时预览
    realTime(data) {
      this.previews = data;
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.options.img = reader.result;
        };
      }
    },
    // 上传图片
    uploadImg() {
      this.$refs.cropper.getCropBlob(data => {
        let formData = new FormData();
        formData.append("avatarfile", data);
        uploadAvatar(formData).then(response => {
          if(response.code==200){
            this.$message.success("上传成功！");
            this.open = false;
            this.options.img = response.imgUrl;
            this.avatar=response.imgUrl;
            // this.$store.commit('SET_AVATAR', this.options.img);
            this.visible = false;
          }

        });
      });
    },
  },
  created () {

  }
}
</script>

<style scoped>


.avatar-upload-preview {
	position: absolute;
	top: 50%;
	transform: translate(50%, -50%);
	width: 200px;
	height: 200px;
	border-radius: 50%;
	box-shadow: 0 0 4px #ccc;
	overflow: hidden;
}


.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date{
  width: 1.864583rem;
}


.message{
  padding: 45px 50px;
  text-align: left;
}
.me_head{
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 800;
}
form{
  padding: 40px 0px 0px 70px;
}
.me_photo{
  padding-left: 50px;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.me_img{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.me_img img{
  width: 100%;
  height: 100%;
}
.me_photo span{
  font-size:14px;
  color: #FFFFFF;
  padding-left: 13px;
  cursor: pointer;
}
.me_list{
  display: flex;
  margin-bottom: 20px;
}
.me_strp{
  display: flex;
  align-items: center;
}
.me_strp label{
  display: inline-block;
  width: 80px;
  text-align: right;
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 15px;
}
.me_strp >>> input{
  width: 358px;
  height: 40px;
  background: rgba(255, 255, 255, 0.17);
  border: 1px solid #FFFFFF;
  opacity: 0.49;
  border-radius: 2px;
  outline: none;
  padding: 0px 10px;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 14px;
  padding-left: 30px;
}
.me_strp:nth-child(2){
  padding-left: 100px;
}
.gender{
  width: 358px;
  display: inline-block;
}
.me_flex{
  display: flex;
}
.me_flex p{
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 25px;
}
.me_flex span{
  display: inline-block;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin-right: 10px;
  cursor: pointer;
}
.me_flex .genderActive{
  background: #D6DA00;
}
.me_flex span img{
  vertical-align: middle;
}
.submit{
  width: 950px;
  border-top:1px solid rgba(255,255,255,.09);
  margin-left: 90px;
  margin-top: 40px;
  padding-top: 45px;
  display: flex;
}
.submit p{
  width: 358px;
  height: 48px;
  background: #D6DA00;
  border-radius: 2px;
  font-size: 14px;
  color: #333333;
  margin: auto;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
</style>

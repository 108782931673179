<template>
  <div class="collect">
    <div class="cotab">
      <h3 v-for="(tab,tabindex) in tabdata" :key="tabindex" :class="tabactive == tabindex?'tabactive':''" @click="onSwitch(tabindex)">{{tab.text}}</h3>
    </div>
    <div class="co_head">
      <p @click="onEdit"><img src="../../assets/images/personage/bianji.png">编辑</p>
      <p v-show="select" @click="onCheck"><span :class="checkall?'checkall':''"><input type="checkbox" ref="quanxuan"></span>全选</p>
      <p v-show="select" class="delete" @click="ondelete">删除</p>
    </div>
    <!--图片收藏-->
    <div class="store" v-show="tabactive==0">
      <ul class="have">
        <li v-for="(lis,lisindex) in haveData" :key="lisindex" >
          <div class="pitch" v-show="select" @click="onSelect(lis)"><span :class="checkedKeys.includes(lis.id)?'ctrl':''"><input type="checkbox" ref="optional"></span></div>
          <div class="haveImg" @click="strpitem(lis)">
            <img :src="lis.titleImage" v-if="lis.titleImage">
            <div class="default" v-else></div>
          </div>
          <div class="descr">
            <p class="ellipsis">{{lis.name}}</p>
            <span>收藏时间：{{lis.createDate}}</span>
          </div>
        </li>
      </ul>
    </div>
    <!--文字收藏-->
    <div class="store"  v-show="tabactive==1">
      <ul class="have texthave">
        <li v-for="(lis,lisindex) in textData" :key="lisindex" >
          <div class="pitch" v-show="select" @click="onSelect(lis)"><span :class="checkedKeys.includes(lis.id)?'ctrl':''"><input type="checkbox" ref="optional"></span></div>
          <div class="descr"  @click="strpitem(lis)">
            <p><label class="raw">原文</label><span class="ellipsis">{{lis.name}}</span></p>
            <p><label>收藏</label><span class="ellipsis">{{lis.txt}}</span></p>
            <span>收藏时间：{{lis.createDate}}</span>
          </div>
        </li>
      </ul>
    </div>
    <el-pagination
        background
        layout="total,prev, pager, next"
        :page-size="page.pageSize"
        :total="page.totalCount"
        @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { getUserCollectList,collectItem} from "@/api/userCenter";
export default {
  name: "collect",
  data(){
    return{
      haveData:[],//条目收藏
      textData:[],//文字收藏
      select:false,
      checkall:false,
      checkedKeys:[],
      page: {
        totalCount:0
      },
      pageNo: 1,
      tabactive:0,
      tabdata:[
        {text:'条目收藏'},
        {text:'文字收藏'}
      ]
    }
  },
  methods:{
    /*切换图片、文字收藏*/
    onSwitch(tabindex){
      this.checkedKeys=[];
      this.tabactive = tabindex;
      this.pageNo=1;
      if(tabindex==1 && this.textData.length==0){
        this.getCollectText();
      }

    },
    /*编辑*/
    onEdit(){
      this.select = !this.select;
    },
    /*选中*/
    onSelect(lis){
      var keys = lis.id;
      if(this.checkedKeys.includes(keys)){
        var index = this.checkedKeys.indexOf(keys);
        this.checkedKeys.splice(index,1);
      }else {
        this.checkedKeys.push(keys);
      }
    },
    /*删除*/
    ondelete(){
      collectItem('del',
          {
            type: 0,
            ids: this.checkedKeys.join(','),
          }).then((res) => {
        this.pageNo = 1;
        if(this.tabactive==1){
          this.getCollectText();
        }else{
          this.getCollect();
        }

      })
    },
    /*全选*/
    onCheck(){
      this.select = true;
      this.checkall = !this.checkall;
      if(this.checkall){
        for (var i=0; i<this.haveData.length; i++){
          const item = this.haveData[i];
          if (!this.checkedKeys.includes(item.id)){
            this.checkedKeys.push(item.id)
          }
        }
      }else{
        this.checkedKeys = [];
      }
    },
    /*条目收藏列表*/
    getCollect(){
      getUserCollectList({pageNo: this.pageNo,pageSize: 20}).then((res) => {
        this.haveData = res.data.list.map((item)=>{
          return {
            id:item.id,
            uuid:item.item.uuid,
            createDate:item.createDate,
            name:item.item.name,
            titleImage:item.item.titleImage?(item.item.titleImage.indexOf("?")>=0?(item.item.titleImage+'&h=300'):(item.item.titleImage+'?h=300')):''}
        });
        this.page = res.data;
      })
    },
    /*文本收藏列表*/
    getCollectText(){
      getUserCollectList({pageNo: this.pageNo,pageSize: 20,type:1}).then((res) => {
        this.textData = res.data.list.map((item)=>{
          return {
            id:item.id,
            uuid:item.item.uuid,
            createDate:item.createDate,
            name:item.item.name,
            txt:item.txt,
            }
        });
        this.page = res.data;
      })
    },
    /*点击跳转*/
    strpitem(lis) {
      this.$router.push({
        name: "item",
        query: { sortId: lis.uuid },
      });
    },
    /*分页*/
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getCollect();
    },
  },
  created() {
    this.getCollect()
  }
}
</script>

<style scoped>
.collect{
  padding: 45px 0px 45px 50px;
  text-align: left;
}
.cotab{
  padding-bottom: 20px;
  border-bottom: 1px transparent solid;
  border-image:linear-gradient(to right,#3C3934, #130F0C) 20 20;
  margin-bottom: 20px;
}
.cotab h3{
  font-size: 20px;
  color: #FFFFFF;
  font-weight: 800;
  padding-right: 50px;
  display: inline-block;
  cursor: pointer;
}
.cotab .tabactive{
  color: #FF5252;
}
.co_head{
  display: flex;
  align-items: center;
}
.co_head p{
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 35px;
  cursor: pointer;
}
.co_head p img,.co_head p span{
  margin-right: 5px;
}
.co_head p span{
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.18);
  border: 1px solid rgba(255, 255, 255, 0.42);
  border-radius: 2px;
  display: inline-block;
  vertical-align: text-top;
}
.co_head p .checkall{
  background-image: url("../../assets/images/personage/xuanzhong.png");
  background-size: 8px 6px;
  background-repeat: no-repeat;
  background-position: center center;
}
.co_head p input{
  display: none;
  width: 14px;
  height: 14px;
}
.co_head .delete{
  color: #D6DA00;
}
.store{
  height: 720px;
  overflow-y: auto;
}
.have{
  display: flex;
  margin-top: 23px;
  flex-wrap: wrap;
}
.store::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 7px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.store::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background   : #B3B3B3;
}
.store::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #3A362D;
}
.have li{
  width: 365px;
  height: 295px;
  position: relative;
  margin-right: 35px;
  margin-bottom: 20px;
}
.have li:nth-child(4n){
  margin-right: 0;
}
.haveImg{
  width: 100%;
  height: 228px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255,255,255,.1);
  border-bottom: none;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.default{
  position: absolute;
  background-image: url("../../assets/images/fenlei.png");
  background-size: 355px 220px;
  background-position: center center;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.haveImg img{
  max-width: 100%;
  max-height: 100%;
}
.descr{
  width: 100%;
  height: 68px;
  background: rgba(0, 0, 0, 0.34);
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.ellipsis{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.descr p{
  font-size: 16px;
  color: #FFFFFF;
  padding-bottom: 5px;
}
.descr span{
  font-size: 12px;
  color: #FFFFFF;
}
.pitch{
  position: absolute;
  left: 11px;
  top: 11px;
  z-index: 1;
}
.pitch span{
  display: inline-block;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.14);
  border: 1px solid #CCCCCC;
}
.pitch .ctrl{
  background-image: url("../../assets/images/personage/xuanzhong1.png");
  background-size: 14px 11px;
  background-repeat: no-repeat;
  background-position: center center;
}
.pitch span input{
  display: none;
  width: 24px;
  height: 24px;
}
.el-pagination{
  text-align: center;
  padding-top: 30px;
  padding-right: 40px;
}
.el-pagination >>> .el-pagination__total{
  color: #ffffff;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled).active{
  background-color: #D6DA00;
  color: #ffffff;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled):hover{
  color: #D6DA00;
}
.el-pagination.is-background >>> .el-pager li:not(.disabled).active:hover{
  color: #ffffff;
}
/*文字*/
.texthave li{
  height: 110px;
  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255,255,255,.1);
}
.texthave .descr{
  background: none;
  height: 100%;
  padding:15px 15px 15px 50px;
}
.texthave .descr p{
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.texthave .descr p span{
  font-size: 16px;
  line-height: 16px;
}
.texthave .descr label{
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 5px;
  margin-right: 10px;
  line-height: 12px;
  background: #D6DA00;
  color: #2C2C2C;
  vertical-align: middle;
  margin-top: -2px;
  flex-shrink: 0;
}
.texthave .descr .raw{
  color: #ffffff;
  background: rgba(255,255,255,.27);
}
.texthave .descr>span{
  color: rgba(255,255,255,.63);
  margin-top: 5px;
  display: block;
}
</style>
